export class CollectionFilters {
  constructor() {
    this.filterButtons = Array.from(
      document.querySelectorAll('.filter-button')
    );
    this.modal = document.getElementById("collection-filters-modal");
    this.drawer = document.getElementById("collection-filters-drawer");
    this.overlay = document.getElementById("collection-filters-overlay");
    this.isMobile = window.matchMedia('(max-width: 767px)');
    this.boundOnClickFilterButton = this.onClickFilterButton.bind(this);
    this.tabButtons = Array.from(document.querySelectorAll('.tablinks'));
  }

  onClickCloseModal(e) {
    const { target, currentTarget } = e;
    if (currentTarget === null) {
      return;
    }
    const content = currentTarget.querySelector(".collection-filters__stage");
    if (content === null || (target !== content && !content.contains(target)) || (target === currentTarget.querySelector('#applyFilters'))) {
      this.closeModal();
    }
  }

  onClickCloseDrawer(e) {
    const { target, currentTarget } = e;
    if (currentTarget === null) {
      return;
    }
    const content = currentTarget.querySelector(".collection-filters__stage");
    if (content === null || (target !== content && !content.contains(target)) || (target === currentTarget.querySelector('#applyFilters'))) {
      this.closeDrawer();
    }
  }

  openModal(targetOption) {
    this.modal.classList.add("is-open");
    document.body.classList.add("is-collection-filters-open");
    this.modal.addEventListener("click", (e) => this.onClickCloseModal(e));
    this.setActiveTab(this.modal, targetOption);
    this.tabButtons.forEach((button) => {
      button.addEventListener('click', this.toggleFilterTabs);
    });
  }

  closeModal() {
    this.modal.classList.remove("is-open");
    this.modal.removeEventListener("click", (e) => this.onClickCloseModal(e));
    document.body.classList.remove("is-collection-filters-open");
    this.tabButtons.forEach((button) => {
      button.removeEventListener('click', this.toggleFilterTabs);
    });
  }

  openDrawer(targetOption) {
    this.drawer.classList.add("is-open");
    this.overlay.classList.add("is-open");
    document.body.classList.add('no-scroll');
    this.drawer.addEventListener("click", (e) => this.onClickCloseDrawer(e));
    this.overlay.addEventListener("click", (e) => this.onClickCloseDrawer(e));
    this.setActiveTab(this.drawer, targetOption);
    this.tabButtons.forEach((button) => {
      button.addEventListener('click', this.toggleFilterTabs);
    });
  }

  closeDrawer() {
    this.drawer.classList.remove("is-open");
    this.drawer.removeEventListener("click", (e) => this.onClickCloseDrawer(e));
    this.overlay.classList.remove("is-open");
    this.overlay.removeEventListener("click", (e) => this.onClickCloseDrawer(e));
    document.body.classList.remove('no-scroll');
    this.tabButtons.forEach((button) => {
      button.removeEventListener('click', this.toggleFilterTabs);
    });
  }

  onClickFilterButton(e) {
    e.preventDefault();
    var targetOption = e.target.id;
    if (this.isMobile.matches) {
      this.openModal(targetOption);
    } else {
      this.openDrawer(targetOption);
    }
  }

  setActiveTab(container, targetOption) {
    if (targetOption == 'sortTrigger') {
      container.querySelector('.sortWrapper').classList.remove('visually-hidden');
      container.querySelector('.filtersWrapper').classList.add('visually-hidden');
      container.querySelector('#sortTabButton').classList.add('active');
      container.querySelector('#filterTabButton').classList.remove('active');
    } else if (targetOption == 'filtersTrigger') {
      container.querySelector('.filtersWrapper').classList.remove('visually-hidden');
      container.querySelector('.sortWrapper').classList.add('visually-hidden');
      container.querySelector('#filterTabButton').classList.add('active');
      container.querySelector('#sortTabButton').classList.remove('active');
    }
  }

  toggleFilterTabs(e) {
    console.log(e);
    // Declare all variables
    var i, tabcontent, tablinks;
    var option = e.target.dataset.option;
  
    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.add("visually-hidden");
    }
  
    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
  
    // Show the current tab, and add an "active" class to the button that opened the tab
    e.currentTarget.className += " active";

    document.querySelectorAll(`.${option}`).forEach(function (el) {
        console.log(el);
        el.classList.remove("visually-hidden");
        return false;
    });

  }
  
  init() {
    this.filterButtons.forEach((link) => {
      link.addEventListener('click', this.boundOnClickFilterButton);
    });

    this.isMobile.onchange = (e) => {
      this.filterButtons.forEach((link) => {
        if (e.matches) {
          link.addEventListener('click', this.boundOnClickFilterButton);
        } else {
          link.removeEventListener('click', this.boundOnClickFilterButton);
        }
      });
    };
  }
}

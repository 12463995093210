export const Swipers = () => {
  // Buttons carousel
  if (document.querySelector('.buttons-carousel__buttons')) {
    let btnsMobileSwiper;

    function initSwiper() {
      if (window.matchMedia('(max-width: 1024px)').matches) {
        if (!document.querySelector('.buttons-carousel__buttons').classList.contains('swiper-initialized')) {
          btnsMobileSwiper = new Swiper('.buttons-carousel__buttons', {
            slidesPerView: 'auto',
            spaceBetween: 10,
            noSwiping: false,
            loop: false,
            navigation: {
              nextEl: `.next-button`,
              prevEl: `.prev-button`,
            },
          });
        }
      } else if (btnsMobileSwiper) {
          btnsMobileSwiper.disable();
          btnsMobileSwiper.destroy(true, true);
          btnsMobileSwiper = null;
      }
    }

    initSwiper();

    window.addEventListener('resize', initSwiper);
  }

  // Images carousel
  if (document.querySelector('.images-carousel__images')) {
    let imageSwiper;

    let imagesLength = document.querySelector('.images-carousel__images').dataset.length;

    console.log(imagesLength);

    function initImagesSwiper() {
        if (!document.querySelector('.images-carousel__images').classList.contains('swiper-initialized')) {
          imageSwiper = new Swiper('.images-carousel__images', {
            slidesPerView: imagesLength > 3 ? 3 : imagesLength,
            spaceBetween: 10,
            noSwiping: false,
            loop: false,
            autoHeight: false,
            navigation: {
              nextEl: `.next-button`,
              prevEl: `.prev-button`,
            },
            breakpoints: {
              750: {
                slidesPerView: imagesLength > 8 ? 8 : imagesLength,
              },
            },
          });
        }
  
    }

    initImagesSwiper();

    window.addEventListener('resize', initImagesSwiper);
  }

  // ==================================================*
  // ===* FEATURED COLLECTION SWIPER ===*
  // ==================================================*
  if (document.querySelector('.featured-collection-swiper')) {
    const swiper = new Swiper('.featured-collection-swiper', {
      slidesPerView: 2,
      navigation: {
        nextEl: `.next-button`,
        prevEl: `.prev-button`,
      },
      spaceBetween: 10,
      noSwiping: false,
      loop: true,
      breakpoints: {
        750: {
          slidesPerView: 3,
        },
      },
    });
    const slideImageHeight = document
      .querySelector('.featured-collection-swiper')
      .querySelector('.swiper-slide .image-container');
    const productCardHeight = document
      .querySelector('.featured-collection-swiper')
      .querySelector('.swiper-slide .product--card');
    if (slideImageHeight && productCardHeight) {
      let imgHeight = slideImageHeight.clientHeight;
      let productHeight = productCardHeight.clientHeight;

      const collectionSwipers = document.querySelectorAll(
        '.featured-collection-swiper'
      );
      collectionSwipers.forEach((c) => {
        c.setAttribute(
          'style',
          `--slide-image-height: ${imgHeight}px; --product-card-height: ${productHeight}px;`
        );
      });
    }
  }

  // ===* FEATURED COLLECTION -> LABELS <- ===*
  if (document.querySelector('.featured-collection-swiper-labels')) {
    const swiper = new Swiper('.featured-collection-swiper-labels', {
      slidesPerView: 1,
      spaceBetween: 0,
      nested: true,
      noSwiping: false,
      loop: true,
      infinite: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      breakpoints: {
        750: {
          slidesPerView: 1,
        },
      },
    });

    const slideImageHeight = document
      .querySelector('.featured-collection-swiper-labels')
      .querySelector('.swiper-slide .image-container');
    const productCardHeight = document
      .querySelector('.featured-collection-swiper-labels')
      .querySelector('.swiper-slide .product--card');
    if (slideImageHeight && productCardHeight) {
      let imgHeight = slideImageHeight.clientHeight;
      let productHeight = productCardHeight.clientHeight;

      const collectionSwipers = document.querySelectorAll(
        '.featured-collection-swiper-labels'
      );
      collectionSwipers.forEach((c) => {
        c.setAttribute(
          'style',
          `--slide-image-height: ${imgHeight}px; --product-card-height: ${productHeight}px;`
        );
      });
    }
  }

  // ==================================================*
  // ===* OUTLET COLLECTION SWIPER ===*
  // ==================================================*
  if (document.querySelector('.outlet-collection-swiper')) {
    const swiper = new Swiper('.outlet-collection-swiper', {
      slidesPerView: 2,
      navigation: {
        nextEl: `.next-button`,
        prevEl: `.prev-button`,
      },
      spaceBetween: 10,
      loop: false,
      infinite: true,
      breakpoints: {
        1100: {
          slidesPerView: 3,
        },
      },
    });
    const slideImageHeight = document
      .querySelector('.outlet-collection-swiper')
      .querySelector('.swiper-slide .image-container');
    const productCardHeight = document
      .querySelector('.outlet-collection-swiper')
      .querySelector('.swiper-slide .product--card');
    if (slideImageHeight && productCardHeight) {
      let imgHeight = slideImageHeight.clientHeight;
      let productHeight = productCardHeight.clientHeight;

      const collectionSwipers = document.querySelectorAll(
        '.outlet-collection-swiper'
      );
      collectionSwipers.forEach((c) => {
        c.setAttribute(
          'style',
          `--slide-image-height: ${imgHeight}px; --product-card-height: ${productHeight}px;`
        );
      });
    }
  }
  if (document.querySelectorAll('.collection-swiper')) {
    document.querySelectorAll('.collection-swiper').forEach((s) => {
      let swiper = new Swiper(s, {
        slidesPerView: 2,
        navigation: {
          nextEl: `.next-button`,
          prevEl: `.prev-button`,
        },
        spaceBetween: 10,
        loop: true,
        infinite: true,
        breakpoints: {
          750: {
            slidesPerView: 4,
          },
        },
      });
      let swiperHeight = s.querySelector(
        '.swiper-slide .image-container'
      ).offsetHeight;
      s.querySelectorAll('.swiper-button').forEach((btn) => {
        btn.setAttribute('style', `top: ${(swiperHeight / 2).toFixed(0)}px;`);
      });
    });
  }

  // ==================================================*
  // ===* SHOP BY SWIPER ===*
  // ==================================================*
  if (document.querySelector('.shop-by-swiper')) {
    const swiper = new Swiper('.shop-by-swiper', {
      slidesPerView: 2.15,
      navigation: {
        nextEl: `.next-button`,
        prevEl: `.prev-button`,
      },
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        320: {
          slidesPerView: 2.15,
        },
        600: {
          slidesPerView: 3,
        },
        750: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 6,
        },
      },
    });
  }

    // ==================================================*
  // ===* ARTICLE SWIPER ===*
  // ==================================================*
  if (document.querySelector('.article-read-more-swiper')) {
    const swiper = new Swiper('.article-read-more-swiper', {
      slidesPerView: 1,
      navigation: {
        nextEl: `.next-button`,
        prevEl: `.prev-button`,
      },
      spaceBetween: 10,
      loop: true,
      infinite: true,
      breakpoints: {
        750: {
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector('.four-swiper')) {
    const swiper = new Swiper('.four-swiper', {
      slidesPerView: 2.15,
      navigation: {
        nextEl: `.swiper-button-next`,
        prevEl: `.swiper-button-prev`,
      },
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        320: {
          slidesPerView: 2.15,
        },
        600: {
          slidesPerView: 3,
        },
        750: {
          slidesPerView: 4,
        },
      },
    });
  }

  // ==================================================*
  // ===* CTA CARD SWIPER ===*
  // ==================================================*
  if (document.querySelector('.cta-card-swiper')) {
    const swiper = new Swiper('.cta-card-swiper', {
      loop: false, // <- causes issues combined with multi row setup!!
      slidesPerView: 4,
      slidesPerColumn: 2,
      spaceBetween: 30,
      slidesPerColumnFill: 'row',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 2.15,
        },
        600: {
          slidesPerView: 3,
        },
        750: {
          slidesPerView: 4,
        },
      },
    });
  }

  // ==================================================*
  // ===* PRODUCT SWIPER ===*
  // ==================================================*
  if (document.querySelector('.product-media-swiper')) {
    const swiper = new Swiper('.product-media-swiper', {
      loop: false, // <- causes issues combined with multi row setup!!
      slidesPerView: 1.2,
      spaceBetween: 20,
      touchEventsTarget: 'container',
    });
  }
};
